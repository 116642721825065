
import { defineComponent } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import TmColoredUl from '@/components/shared/TmColoredUl.vue'
import TmStylizedText from '@/components/shared/TmStylizedText.vue'
import TmButton from '@/components/shared/TmButton.vue'
import AdminWorkspaceTeamMembersFaq from '@/components/pages/admin/workspace/teamMembers/AdminWorkspaceTeamMembersFaq.vue'
import type { RouteLocationRaw } from 'vue-router'

type ItemPlanType = {
  icon: string;
  title: string;
  text: string;
  points: any;
  to: RouteLocationRaw;
}

export default defineComponent({
  components: {
    AdminWorkspaceTeamMembersFaq,
    TmButton,
    TmStylizedText,
    TmColoredUl,
    WhiteBlock,
    PageContent,
  },
  setup() {
    const plans: ItemPlanType[] = [
      {
        icon: 'people',
        title: 'Users',
        to: { name: 'base.admin.users' },
        text: 'Suitable for a team collaborating within one shared workspace.',
        points: [
          'Single workspace for collaboration',
          [
            'Shared ',
            {
              text: 'virtual numbers',
              style: 'semi-bold',
            },
          ],
          [
            'Shared ',
            {
              text: 'chats',
              style: 'semi-bold',
            },
            ' and messages',
          ],
          [
            'Shared ',
            {
              text: 'contacts',
              style: 'semi-bold',
            },
            ' and lists',
          ],
          [
            {
              text: 'One billing',
              style: 'semi-bold',
            },
            ' account',
          ],
          [
            'Active ',
            {
              text: 'monthly plan',
              style: 'semi-bold',
            },
            ' required',
          ],
        ],
      },
      {
        icon: 'domain',
        title: 'Sub-accounts',
        to: { name: 'base.admin.subAccounts' },
        text: 'Suitable for separate departments, locations or operating units.',
        points: [
          'Separate workspaces ',
          [
            'Separate ',
            {
              text: 'virtual numbers',
              style: 'semi-bold',
            },
          ],
          [
            'Separate ',
            {
              text: 'chats',
              style: 'semi-bold',
            },
            ' and messages',
          ],
          [
            'Separate ',
            {
              text: 'contacts',
              style: 'semi-bold',
            },
            ' and lists',
          ],
          [
            {
              text: 'One billing',
              style: 'semi-bold',
            },
            ' account',
          ],
          [
            'Active ',
            {
              text: 'monthly plan',
              style: 'semi-bold',
            },
            ' required',
          ],
        ],
      },
    ]

    return {
      plans,
    }
  },
})
