import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass(["tm-colored-ul", {
      [`tm-colored-ul--${_ctx.color}`]: _ctx.color,
      'tm-colored-ul--horizontal': _ctx.horizontal
    }]),
    style: _normalizeStyle(`--line-height: ${_ctx.lineHeight}; --circle-size: ${_ctx.circleSize};`)
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 6))
}