
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

type CircleColors = '' | 'blue' | 'green' | 'red' | 'orange' | 'gray' | 'gray-light' | 'neutral' | 'light' | 'emphasize'

export default defineComponent({
  name: 'tmColoredUl',
  props: {
    circleSize: {
      type: String,
      default: '6px',
    },
    lineHeight: {
      type: String,
      default: '20px',
    },
    color: {
      type: String as PropType<CircleColors>,
      default: 'blue',
    },
    horizontal: {
      type: Boolean,
    },
  },
})
