
import { defineComponent, ref } from 'vue'
import FaqBlock from '@/components/shared/templates/FaqBlock.vue'
import TmExpansion from '@/components/shared/TmExpansion.vue'

export default defineComponent({
  components: {
    FaqBlock,
    TmExpansion,
  },
  setup() {
    const tabs = ref([
      {
        name: 'users',
        label: 'Users',
      },
      {
        name: 'sub-accounts',
        label: 'Sub-accounts',
      },
    ])

    return {
      tabs,
    }
  },
})
